import { Vue, Component } from 'vue-property-decorator';
import { Language } from '@/i18n';

import { requireLegalBanner } from '../../../../whitelabel.config';

@Component({})

export default class BannerLegal extends Vue {
  requireLegalBanner = requireLegalBanner;

  get imgURL(): any {
    const image = require('@/assets/images/bannerLegal/banner-legal.jpg');
    switch ((this.$i18n.locale as Language)) {
      case 'nl':
        return image;
      case 'en':
        return image;
      default: return image;
    }
  }
}
